import { useCallback } from 'react';
import styled from 'styled-components';
import { DateTime } from 'luxon';
import { Button } from '../AkidoUI';
import { TestID } from '../../constants/testIds';
import { Clients } from '../../constants/clients';
import { ScheduleEventItem } from '../../types/schedule';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import { FEATURE_FLAGS } from '../../config/launchdarkly';
import { REACT_APP_AKIDO_CHART_URL } from '../../config/environment';
import { PatientTypes } from '../../services/graphql/commonTypes';
import ConditionalRender from '../ConditionalRender/ConditionalRender';

const EventContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
`;

const EventLabel = styled.div`
  font-weight: 700;
  font-size: 14px;
  margin-bottom: 12px;
`;

const LeftContainer = styled.div``;

const RightContainer = styled.div``;

const PatientWrapper = styled.div`
  display: flex;
  gap: 4px;
  font-size: 14px;
`;

export function Event({ event }: { event: ScheduleEventItem }) {
  const { end, start } = event;
  const patient = event?.patient;
  const from = start ? DateTime.fromJSDate(start).toFormat('h:mm a') : '';
  const to = end ? DateTime.fromJSDate(end).toFormat('h:mm a') : '';
  const ldClient = useLDClient();
  const shouldUseResourceIdentifier = ldClient?.variation(
    FEATURE_FLAGS.RESOURCE_ID_PARAM,
    false
  );

  const shouldRedirectToAkidoChart = ldClient?.variation(
    FEATURE_FLAGS.REDIRECT_TO_AKIDO_CHART,
    false
  );

  const redirectHost = shouldRedirectToAkidoChart
    ? REACT_APP_AKIDO_CHART_URL
    : '';

  const handleOnOpenNoteClick = useCallback(() => {
    if (event?.encounterId) {
      if (!shouldUseResourceIdentifier) {
        window.open(
          `${redirectHost}/clinical-notes?externalEncounterId=${event?.encounterId?.value}&client=${Clients.CMG}`
        );
      } else {
        window.open(
          `${redirectHost}/clinical-notes?resourceIdType=${event?.encounterId?.type}&resourceId=${event?.encounterId?.value}&client=${Clients.CMG}`
        );
      }
    }
  }, [event?.encounterId, shouldUseResourceIdentifier, redirectHost]);

  return (
    <EventContainer data-testid={TestID.Calendar.CalendarEvent}>
      <LeftContainer>
        <EventLabel>
          {from} - {to}
        </EventLabel>
        <PatientWrapper>
          <ConditionalRender
            shouldRender={
              patient?.accountNumber?.type !== PatientTypes.FHIR_PATIENT_ID
            }
          >
            <div data-testid='account-number'>
              #{patient?.accountNumber?.value}
            </div>
            <div>|</div>
          </ConditionalRender>
          <div data-private>{patient?.birthDate}</div>
          <div data-private>{patient?.fullName}</div>
        </PatientWrapper>
      </LeftContainer>
      <RightContainer>
        <Button
          variant='primary'
          label='Open Note'
          testId={`${TestID.Calendar.OpenNoteButton}-${event?.encounterId?.value}`}
          styles={{ backgroundColor: '#0078D8', border: 0 }}
          onClick={handleOnOpenNoteClick}
        />
      </RightContainer>
    </EventContainer>
  );
}
