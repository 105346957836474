import FeatureFlaggedConditionalRender from '../FeatureFlaggedConditionalRender/FeatureFlaggedConditionalRender';
import { FEATURE_FLAGS } from '../../config/launchdarkly';
import { TestID } from '../../constants/testIds';
import { ExternalLink } from 'lucide-react';
import authStore from '../../stores/auth.store';
import { useState } from 'react';
import { Badge } from '../../components/ui/badge';
import { gql, useSubscription } from '@apollo/client';
import { useFeatureFlag } from '../../hooks/useFeatureFlag';

export function MyTasksLink() {
  const userId = authStore.authInfo?.sub;
  const [notificationCount, setNotificationCount] = useState(0);
  const flagData: { listUrl: string } = useFeatureFlag(
    FEATURE_FLAGS.TASKS_CONFIG,
    {
      listUrl: '',
    }
  );
  useSubscription(
    gql`
      subscription WatchTaskAssigned($assignedUser: String!) {
        taskAssigned(assignedUser: $assignedUser) {
          assignedUserId
          content
        }
      }
    `,
    {
      onData() {
        setNotificationCount((prev) => prev + 1);
      },
      variables: {
        assignedUser: userId,
      },
      skip: !userId,
    }
  );

  const showBadge = notificationCount > 0;

  const handleClick = () => {
    setNotificationCount(0);
  };

  return (
    <FeatureFlaggedConditionalRender featureFlag={FEATURE_FLAGS.MY_TASKS_LINKS}>
      <a
        data-testId={TestID.Header.MyTasksLink}
        className='font-500 mr-[90px] flex cursor-pointer items-center rounded-md border px-4 py-2 text-xs text-[#94a3b8] hover:opacity-60'
        onClick={handleClick}
        href={`${flagData?.listUrl}`}
        target='_blank'
        rel='noreferrer noopener'
      >
        {showBadge && (
          <Badge
            data-testid='notification-badge'
            className='mr-3'
            variant='destructive'
          >
            New
          </Badge>
        )}
        My Tasks
        <ExternalLink size={16} className='mb-0.5 ml-2' />
      </a>
    </FeatureFlaggedConditionalRender>
  );
}
